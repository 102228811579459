import Repository from "@/lib/https/repository";
import router from "@/router";
//import {useStore} from "vuex"
//const store = useStore()
const urlType = "/type";
const urlOCR = "/ocr";
const urlOCRBill = "/ocr-bill";
const urlLang = "/language";
const urlTrans = "/translate";
const urlFile = "/file";
const urlContact = "/contact";

export default class AuthRepository extends Repository {

  language = async () => {
    try {
      const dataLang = await this.axios.get(urlLang);
      
      return dataLang;
    } catch (error) {
      console.log(error);
    }
  }

  type = async () => {
    //const current = new Date();
    try {
      //console.log(current.getMilliseconds());
      //const data = await this.axios.get(urlType, {timeout: 1000});
      const data = await this.axios.get(urlType);
      //const current2 = new Date();
      //console.log(current.getMilliseconds());
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  languageTranslate = async () => {
    try {
      const dataLangTrans = await this.axios.get(urlTrans);
      return dataLangTrans;
    } catch (error) {
      console.log(error)
    }
  };

  image = async (FormValue: FormData , store: any) => {
    try {
      const config = {
        onUploadProgress: (progressEvent: any) => {
          //console.log(progressEvent);
          //const loadingPercent = Math.floor((progressEvent.loaded * 10 / progressEvent.total) )
          const loadingPercent = Math.floor((progressEvent.loaded* 50 / progressEvent.total) )
          //console.log(loadingPercent);
          store.commit("loading/SET_LOADING", loadingPercent)
        },
        onDownloadProgress:  (progressEvent: any) => {
          //console.log(progressEvent);
          //const loadingPercent = Math.floor((progressEvent.loaded * 10 / progressEvent.total) )
          const loadingPercent = 50 + Math.floor((progressEvent.loaded* 50 / progressEvent.total) )
          //console.log(loadingPercent);
          store.commit("loading/SET_LOADING", loadingPercent)
        }
      }
      if (FormValue.get("type") == "9") {
        return await this.axios.post(urlOCRBill, FormValue, config);
      } else {
        return await this.axios.post(urlOCR, FormValue, config);
      }
    } catch (error) {
      //alert("error302")
      console.log(error);
    }
  };

  googleTranslate = async (FormValue: FormData) => {
    try {
      const data = await this.axios.post(urlTrans, FormValue);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  file = async (FormValue: FormData) => {
    try {
      const dataFile = await this.axios.post(urlFile, FormValue);
      return dataFile;
    } catch (error) {
      console.log(error);
    }
  };

  contact = async (FormValue: FormData) => {
    try {
      const status = await this.axios.post(urlContact, FormValue);
      return status;
    } catch (error) {
      console.log(error);
    }
  }
}
